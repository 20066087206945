function GoogleMap() {
    return(
        <div className='col-xl-6 col-md-6'>
            <iframe title='googlemap' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3238.808564615168!2d139.71587105112056!3d35.73092633475449!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d6f604ac997%3A0x474239312f1844e3!2z44CSMTcwLTAwMTMg5p2x5Lqs6YO96LGK5bO25Yy65p2x5rGg6KKL77yT5LiB55uu77yR77yV4oiS77ySIOODqeOCpOOCquODs-OCuuODnuODs-OCt-ODp-ODs-axoOiiiw!5e0!3m2!1sja!2sjp!4v1678104865108!5m2!1sja!2sjp" style={{border: 0, width: '100%', aspectRatio: '4/3'}} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>

    );
}

export default GoogleMap;
