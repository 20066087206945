import './css/Company.css';
import GoogleMap from './GoogleMap';

function CompanyInfo(props) {
    return (
        <div className='col-xl-5 col-md-5 text-xl-end text-md-end'>
            <p>{props.name}</p>
        </div>
    );
}

function Company() {
    return (
        <div id='company'>
            <div className='container'>
                <div className='company p-2'>
                    <div className='mb-5 company-top align-items-center text-center'>
                        <span id='neex-company'>会社概要／沿革</span><br></br>
                    </div>
                    <div className='row mb-5'>
                        <CompanyInfo name='社名' />
                        <div className='col-xl-7 col-md-7'>
                            <span>株式会社ニークス</span>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <CompanyInfo name='代表者' />
                        <div className='col-xl-7 col-md-7'>
                            <span>代表取締役　安食 広司</span>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <CompanyInfo name='本社所在地' />
                        <div className='col-xl-7 col-md-7'>
                            <span>
                                〒336-0023<br/>
                                埼玉県さいたま市南区神明2-3-15<br/>
                                TEL.048-839-0790
                            </span>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <CompanyInfo name='池袋オフィス' />
                        <div className='col-xl-7 col-md-7'>
                            <span>
                                東京都豊島区東池袋3-15-2<br/>
                                ライオンズマンション池袋806
                            </span>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <CompanyInfo name='アクセス' />
                        <GoogleMap />
                    </div>

                    <div className='row mb-5'>
                        <CompanyInfo name='設立' />
                        <div className='col-xl-7 col-md-7'>
                            <span>
                                2007年8月
                            </span>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <CompanyInfo name='資本金' />
                        <div className='col-xl-7 col-md-7'>
                            <span>
                                100万円
                            </span>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <CompanyInfo name='従業員数' />
                        <div className='col-xl-7 col-md-7'>
                            <span>
                                22名
                            </span>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <CompanyInfo name='役員' />
                        <div className='col-xl-7 col-md-7'>
                            <span>
                                取締役　安食 広司
                            </span>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <CompanyInfo name='顧問税理士' />
                        <div className='col-xl-7 col-md-7'>
                            <span>
                                あおば共同会計事務所　根岸税務会計事務所<br/>
                                税理士　根岸 康之
                            </span>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <CompanyInfo name='労働者派遣' />
                        <div className='col-xl-7 col-md-7'>
                            <span>
                                派11-300989
                            </span>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <CompanyInfo name='応募方法' />
                        <div className='col-xl-7 col-md-7'>
                            <span>
                                <a href='https://forms.gle/azPqPdNLZ112cD2Z6' rel='noreferrer' target='_blank'>エントリーフォーム</a>
                                よりお問い合わせください。<br/>
                                弊社採用担当者より折り返しメールにてご連絡差し上げます。
                            </span>
                        </div>
                    </div>

                    <div className='mb-5 align-items-center text-center' id='company-history'>
                        <span className='company-top'>沿革</span><br></br>
                    </div>
                    <div className='row mb-5'>
                        <CompanyInfo name='2023年4月' />
                        <div className='col-xl-7 col-md-7'>
                            <span>WEBサイトリニューアル</span>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <CompanyInfo name='2014年2月' />
                        <div className='col-xl-7 col-md-7'>
                            <span>関東ITソフトウェア健康保険組合加入</span>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <CompanyInfo name='2013年6月' />
                        <div className='col-xl-7 col-md-7'>
                            <span>池袋オフィスへ移転</span>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <CompanyInfo name='2011年2月' />
                        <div className='col-xl-7 col-md-7'>
                            <span>WEBサイトリニューアル</span>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <CompanyInfo name='2009年4月' />
                        <div className='col-xl-7 col-md-7'>
                            <span>WEBサイト開設</span>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <CompanyInfo name='2007年8月' />
                        <div className='col-xl-7 col-md-7'>
                            <span>株式会社ニークス発足</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default Company;
