import React from 'react';
import neex_logo from "./img/neex_logo.png";
import './css/Header.css';

function NavLink(props) {
    return(
        <li className="nav-item">
            <a className="nav-link" href={props.href}>{props.text}</a>
        </li>
    );
}

// 画像パララックス
// 文字の量とサイズの調整
// マウスオーバーの遊び
// 女性の画像は差し替えるor削除
//

function Header() {
    return (
        <nav className="header navbar navbar-expand-lg navbar-light sticky-top">
            <div className="container-fluid">
                <a className="navbar-brand" href="https://neex.co.jp/">
                    <img src={neex_logo} className="App-logo" alt="logo" />
                </a>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end nav-menu" id="navbarNav">
                    <ul className="navbar-nav">
                        <NavLink href="#neex" text="NExt level EXperts" />
                        <NavLink href="#features" text="Features" />
                        <NavLink href="#products" text="Business and Products" />
                        <NavLink href="#recruitment" text="Recruitment" />
                        <NavLink href="#company" text="Company" />
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Header;
