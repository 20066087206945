import './css/Products.css';

function Products() {
    return (
        <div id='products'>
            <div className='container'>
                <div className='products p-2'>
                    <div className='mb-5' id='products-top'>
                        <label id='neex-products'>事業・製品案内</label><br></br>
                    </div>
                    <div>
                        <h3 className='mb-3'>システムインテグレーション</h3>
                        <p>
                            ■システム開発支援<br/>
                            コンサルティング
                        </p>
                        <p>
                            ■システム開発支援<br/>
                            コンサルティング、要件定義、Web技術支援 アプリケーション開発 Java/C/PRO*C/VC++/VC/VB/VB.NET/VBA<br/>
                            C#.NET/PLSQL/Objective-C/bash/KornShell<br/>
                        </p>
                        <p>■基盤技術<br/>
                            運用デザイン、コンサルティング、要件定義、Windows/UNIX/Linux DB2/ORACLE/SQLServer基盤技術支援
                        </p>
                        <p>■ＩＴコーディネイト<br/>
                            販売､生産､物流､購買､ＳＣＭ､ＣＲＭ、ＳＦＡ、グループウェア、ＩＰテレフォニー
                        </p>

                    </div>
                    <div>
                        <h3 className='mb-3'>製品一覧</h3>
                        <p>
                            ■システム開発支援<br/>
                            コンサルティング
                        </p>
                        <p>
                            ■パッケージアプリケーション<br/>
                            顧客管理パッケージ/予約支援パッケージ
                        </p>
                        <p>
                            ■携帯端末向けアプリケーション<br/>
                            iOSアプリケーション
                        </p>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Products;
