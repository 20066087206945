import './css/Recruitment.css';
import img_recruitment from './img/img_recruitment.png';

function RecruitType(props) {
    return(
        <div className='col-xl-5 col-md-5 text-xl-end'>
            <h4>{props.text}</h4>
        </div>
    );
}

function Recruitment() {
    return (
        <div id='recruitment'>
            <div className='container'>
                <div className='recruitment p-2'>
                    <div className='mb-5 align-items-center text-center' id='recruitment-top'>
                        <span>採用情報</span><br></br>
                    </div>
                    <div className='row mb-5'>
                        <RecruitType text='募集職種' />
                        <div className='col-xl-7 col-md-7'>
                            <span>システムエンジニア<br/>
                            プログラマー</span>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <RecruitType text='募集対象' />
                        <div className='col-xl-7 col-md-7'>
                            <span>■キャリア採用<br/>
                            開発経験3ヶ月以上、年齢不問</span>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <RecruitType text='勤務地・勤務時間' />
                        <div className='col-xl-7 col-md-7'>
                            <span>■参画プロジェクトによる<br/>
                            ※東京23区内</span><br/>

                            <span>■池袋オフィス<br/>
                            東京都豊島区東池袋3-15-2　ライオンズマンション池袋806<br/>
                            ◎各線「池袋駅」より徒歩10分<br/>
                            ◎有楽町線「東池袋駅」より徒歩10分</span>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <RecruitType text='給与' />
                        <div className='col-xl-7 col-md-7'>
                            <span>
                                個人売上と基本給により決定<br/>
                                ■給与例<br/>
                                20代　年収520万円<br/>
                                30代　年収650万円<br/>
                                40代　年収800万円
                            </span>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <RecruitType text='休日休暇' />
                        <div className='col-xl-7 col-md-7'>
                            <span>
                                土日祝日、夏期休暇、年末年始休暇、年次有給休暇<br/>
                                その他、参画プロジェクトに則った休日
                            </span>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <RecruitType text='福利厚生' />
                        <div className='col-xl-7 col-md-7'>
                            <span>
                                各種社会保険完備<br/>
                                関東ITソフトウェア健康保険組合加入<br/>
                            </span>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <RecruitType text='応募方法' />
                        <div className='col-xl-7 col-md-7'>
                            <span>
                                <a href='https://forms.gle/azPqPdNLZ112cD2Z6' rel='noreferrer' target='_blank'>エントリーフォーム</a>よりお問い合わせください。<br/>
                                弊社採用担当者より折り返しメールにてご連絡差し上げます。
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='align-items-center'>
                <img className='img-fluid' src={img_recruitment} alt=''></img>
            </div>
        </div>
    );
}

export default Recruitment;
