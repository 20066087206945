import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import Header from './Header';
import TopImages from './TopImages';
import Neex from './Neex';
import Features from './Features';
import Products from './Products';
import Recruitment from './Recruitment';
import Company from './Company';
import Footer from './Footer';
import top_images_08 from "./img/top_images_08.png";
import top_images_09 from "./img/top_images_09.png";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Header />
    <TopImages />
    <Neex />
    <Features />
    <div className='paralax'>
      <img className='paralax01' src={top_images_08} alt=''></img>
    </div>
    <Products />
    <Recruitment />
    <div className='paralax'>
      <img className='paralax02' src={top_images_09} alt=''></img>
    </div>
    <Company />
    <Footer />
  </React.StrictMode>
);
