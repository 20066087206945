import './css/Neex.css';

function Neex() {
    return (
        <div id='neex'>
            <div className='container'>
                <div className='neex p-2'>
                    <div className='mb-5' id='neex-top'>
                        <label id='neex-title'><span>NE</span>xt level <span>EX</span>perts</label><br></br>
                        <label className='mb-2'>次世代IT技術のエキスパート集団</label>
                    </div>
                    <div className='align-items-center' id='box'>
                        <label className='text-white' id='text'>
                            <p>株式会社ニークスは、この世の中をより便利に、アプリケーションやソフトウェアを身近にする企業です。</p>
                            <p>過去では、大企業やごく限られた方の利用するパソコン、ＰＤＡ等でした。
                            現在では、パソコンを持たずとも動くソフトウェアも普及され、ちょっとしたアイディアが大きな利便性や楽しさを生むようになりました。</p>
                            <p>しかしながら、ソフトウェアを開発するには莫大な費用がかかる割に、満足するものが出来なかったなど、<br/>
                            費用対効果が悪くなることもよくあります。現実的にはリスクや実績などから、敬遠されることも少なくありません。</p>
                            <p>我々ニークスでは、本当のニーズに合わせて、必要な機能を効率的に世の中に生み出していきます。</p>
                            <p>それは、大規模なシステムの一部を請け負ったり小さなアプリケーションを作り出したり<br/>
                            IT技術の分野に捉われず、ソフトウェア開発全般を行う企業です。</p>
                            <p>エキスパート集団であるニークスでは、<br/>
                            個々のノウハウを最大限に生かすことにより低コストで世の中の「便利」「楽しさ」に貢献します。</p>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Neex;
