import './css/Features.css';
import img_engineer_first from './img/img_engineer_first.png';
import img_follow from './img/img_follow.png';
import img_up from './img/img_up.png'
import img_salary from './img/img_salary.png'

function Features() {
    return (
        <div id='features'>
            <div className='container'>
                <div className='features p-2'>
                    <div className='mb-3' id='features-top'>
                        <span id='neex-features'>NEEX<span>の</span>特長</span>
                    </div>
                    <div className='row mb-5 align-items-center hover'>
                        <div className='col-lg-4 col-md-6'>
                            <div className=''>
                                <img src={img_engineer_first} alt=''></img>
                            </div>
                        </div>
                        <div className='col-lg-8 col-md-6'>
                            <h3 className='mb-3'>エンジニアファースト</h3>
                                <p>社員一人ひとりのやりたい事を重視し、豊富な案件から希望する仕事を選ぶことができます。</p>
                                <p>Mac支給の案件、リモートワーク希望、私服OKの現場、Rubyがやりたいなど、様々な要望を叶えられます。</p>
                        </div>
                    </div>
                    <div className='row mb-5 align-items-center hover'>
                        <div className='col-lg-4 col-md-6'>
                            <div className=''>
                                <img src={img_follow} alt=''></img>
                            </div>
                        </div>
                        <div className='col-lg-8 col-md-6'>
                            <h3 className='mb-3'>充実なフォロー</h3>
                                <p>社員のプライベート充実のために、福利厚生や休暇が充実しています。</p>
                                <p>積極的な休暇取得を推奨し、産前・産後の育児休暇も取得可能です。</p>
                                <p>関東IT健康保険組合に加入しているので、様々な特典を休暇で満喫しましょう。</p>
                        </div>
                    </div>
                    <div className='row mb-5 align-items-center hover'>
                        <div className='col-lg-4 col-md-6'>
                            <div className=''>
                                <img src={img_up} alt=''></img>
                            </div>
                        </div>
                        <div className='col-lg-8 col-md-6'>
                            <h3 className='mb-3'>確実に給与アップ</h3>
                                <p>ニークス入社後、前職より給与が上がった社員率は100％！</p>
                                <p>ニークスでは、運営にかかわるコストをできる限り削減し、社員に最大限還元しています。</p>
                                <p>また、社員自身のSES単価は個人ごとに公開されており、</p>
                                <p>明確な給与テーブルで会社の経費や利益などの内訳も社員にお伝えしています。</p>
                                <p>また、現場案件意外にも「一括受諾案件」もあるので、有志で参加することで給与アップ！</p>
                        </div>
                    </div>
                    <div className='mt-5 align-items-center text-center'>
                        <div className='col'>
                            <h3>やりたいことして、前職の給与よりアップ！！</h3>
                            <img className='img-fluid' src={img_salary} alt=''></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Features;
