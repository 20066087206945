import PrivacyPolisy from './PrivacyPolicy'
import './wave'

function Footer() {
    return (
        <div className='bg-white pb-3' id='footer'>
            <canvas id="waveCanvas"></canvas>
            <div className='container'>
                <div className="row mb-5 text-gray">
                    <div className="text-center footer-text">
                        <button type="button" className="btn" data-bs-toggle="modal" data-bs-target="#privacyPolisy">
                            プライバシーポリシー
                        </button>
                    </div>

                    <div className="modal fade" id="privacyPolisy" tabIndex={-1}aria-labelledby="exampleModalLabel1" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-scrollable">
                                <div className="modal-content">

                                    <div className="modal-body">
                                        <PrivacyPolisy />
                                    </div>

                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">閉じる</button>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>

                <div className="row mb-3 text-gray">
                    <div className="col text-center">
                        ©2011 Neex Inc.
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Footer;
